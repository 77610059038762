import { Box, Divider, Grid, Stack, Typography, Paper } from "@mui/material";
import Spinner from "components/Spinner";
import { _getOrganization } from "middlewares/OrganizationApi/organization";
import { OrganizationModel } from "object-models/organization-model";
import MultiSelect from "pages/TargetForm/MultiSelect";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmissionByScopeCategoryBarChart from "./EmissionByScopeCategoryBar.Chart";
import { EmissionByScopePie } from "./EmissionByScopePie.Chart";
import pollution from "../../../assets/Icons/pollution.png";
import calendar from "../../../assets/Icons/calendar.png";
import co2 from "../../../assets/Icons/co2.png";
import smoke from "../../../assets/Icons/smoke.png";
import waste from "../../../assets/Icons/waste.png";
import group1 from "../../../assets/Images/dashboard/group1.png";

/**
 * Represents a data item with name and value.
 */
interface DataItem {
  name: string;
  value: number;
}

/**
 * Component for displaying Baseline Target Emission analysis.
 */
export default function BaselineTargetEmission() {
  const { OrganizationId } = useParams();
  const scopesCoveredOptions = [
    { id: 0, label: "Scope 1", value: "Scope 1" },
    { id: 1, label: "Scope 2", value: "Scope 2" },
    { id: 2, label: "Scope 3", value: "Scope 3" },
  ];

  const businessUnitOptions = [
    { id: 0, label: "Unit 1", value: "Unit 1" },
    { id: 1, label: "Unit 2", value: "Unit 2" },
    { id: 2, label: "Unit 3", value: "Unit 3" },
    { id: 3, label: "Unit 4", value: "Unit 4" },
    { id: 4, label: "Unit 5", value: "Unit 5" },
  ];
  const defaultPieChatHeight = 500;
  const defaultHeight = 600;
  const CATEGORY_HEIGHT = 38; // Adjust this value as needed
  const defaultWidth = 700;
  const defaultBarChatWidth = 600;
  const [selectedScope, setSelectedScope] = useState(
    scopesCoveredOptions.map((a) => a.id)
  );
  const [selectedBU, setSelectedBU] = useState(
    businessUnitOptions.map((b) => b.id)
  );

   /**
   * Handles scope change.
   * @param selectedOptions - Array of selected scope options.
   */
  const handleScopeChange = (selectedOptions: number[]) => {
    setSelectedScope(selectedOptions);
  };

   /**
   * Handles business unit change.
   * @param selectedOptions - Array of selected business unit options.
   */
  const handleBusinessUnitChange = (selectedOptions: number[]) => {
    setSelectedBU(selectedOptions);
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([] as unknown as OrganizationModel);
  const [pieChartData, setPieChartData] = useState([] as Array<DataItem>);
  const [barChartData, setBarChartData] = useState([] as Array<DataItem>);
  const [scopeSum, setScopeSum] = useState(0);

  function emissionByScope() {
    if (selectedScope.length > 0) {
      const selectedScopeList = scopesCoveredOptions.filter((s) =>
        selectedScope.includes(s.id)
      );
      return selectedScopeList.map((s) => {
        return (
          <Grid
            key={s.id}
            item
            xs={4}
            sx={{
              boxShadow: 1,
              p: 1,
              m: 1,
              borderRadius: 5,
              backgroundImage: `url(${group1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item>
              <Stack direction={"column"}>
                <Typography
                  sx={{ fontSize: "24px", flex: "1" }}
                  data-testid={s.value}
                >
                  <b>{getSumOfScope(s.value)}</b>
                </Typography>
                <div>{s.label}</div>
              </Stack>
            </Grid>
            <Grid item alignSelf={"end"}>
              <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={getScopeIcon(s.value)}
                alt={s.value + "Icon"}
              />
            </Grid>
          </Grid>
        );
      });
    } else {
      return (
        <Box sx={{ m: 1, mr: 2, mb: 2 }}>
          <Typography variant="h6" color={"primary"}>
            Select Scope in the filter
          </Typography>
        </Box>
      );
    }
  }

    /**
   * Gets the icon URL based on scope value.
   * @param scopeValue - Scope value to determine the icon.
   * @returns Icon URL for the specified scope.
   */
  function getScopeIcon(scopeValue: string) {
    switch (scopeValue) {
      case scopesCoveredOptions[0].value:
        return smoke;
      case scopesCoveredOptions[1].value:
        return pollution;
      case scopesCoveredOptions[2].value:
        return waste;
      default:
        return pollution;
    }
  }

  /**
   * Gets the sum of emissions for a specific scope.
   * @param scope - Scope value to get the sum of emissions.
   * @returns Formatted sum of emissions or "-" if no data found.
   */
  function getSumOfScope(scope: string) {
    const sum = pieChartData
      .filter((d) => d.name === scope)
      .map((d) => d.value)[0];
    return sum > 0 ? sum.toLocaleString("en-US") : "-";
  }

   /**
   * Retrieves pie chart data based on organization data.
   * @returns Array of DataItem objects for pie chart.
   */
  const pieData = (): Array<DataItem> => {
    const ndata = [
      {
        name: "Scope 1",
        value: data.scope1GHGEmission ?? 0,
      },
      {
        name: "Scope 2",
        value: data.scope2GHGEmission ?? 0,
      },
      {
        name: "Scope 3",
        value: data.scope3GHGEmission != null ? Number(data.scope3GHGEmission) : 0
      },
    ];

    return ndata;
  };

   /**
   * Creates a DataItem object with specified name and value.
   * @param name - Name of the data item.
   * @param value - Value associated with the data item.
   * @returns DataItem object with name and value.
   */
  const createDataItem = (name: string, value: number | undefined): DataItem => ({
    name,
    value: value ?? 0,
  });
  
   /**
   * Retrieves bar chart data based on organization data.
   * @returns Array of DataItem objects for bar chart.
   */
  const barData = (): Array<DataItem> => {
    const dataItems = [
      createDataItem("Purchased Goods And Services", data.purchasedGoodsAndServices),
      createDataItem("Capital Goods", data.capitalGoods),
      createDataItem("Fuel and Energy Related Activities", data.fuelandEnergyRelatedActivities),
      createDataItem("Upstream Transportation And Distribution", data.upstreamTransportationAndDistribution),
      createDataItem("Waste Generated In Operations", data.wasteGeneratedinOperations),
      createDataItem("Business Travel", data.businessTravel),
      createDataItem("Employee Commuting", data.employeeCommuting),
      createDataItem("Upstream Leased Assets", data.upstreamLeasedAssets),
      createDataItem("Downstream Transportation And Distribution", data.downstreamTransportationAndDistribution),
      createDataItem("Processing of Sold Products", data.processingofSoldProducts),
      createDataItem("Use of Sold Products", data.useofSoldProducts),
      createDataItem("End of Life Treatment of Sold Products", data.endofLifeTreatmentofSoldProducts),
      createDataItem("Downstream Leased Assets", data.downstreamLeasedAssets),
      createDataItem("Franchises", data.franchises),
      createDataItem("Investments", data.investments),
      createDataItem("Other Upstream", data.otherUpstream),
      createDataItem("Other Downstream", data.otherDownstream),
    ];
  
    return dataItems;
  };

    /**
   * Reshapes pie and bar chart data whenever organization data changes.
   */
  function reshapeData() {
    setPieChartData(pieData);
    
    setBarChartData(barData);
  }

   /**
   * Fetches organization details using the Organization API.
   */
  const fetchOrganizationDetails = async () => {
    try {
      setLoading(true);
      const res = await _getOrganization(Number(OrganizationId));
      if (res) {
        setData(res);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const scopeFilter = scopesCoveredOptions
      .filter((s) => selectedScope.includes(s.id))
      .map((s) => s.value);
    const newPieData = pieData().filter((d) => scopeFilter.includes(d.name));
    if (newPieData.length > 0) {
      setPieChartData(newPieData);
    } else {
      setPieChartData(pieData());
    }
  }, [selectedScope, selectedBU]);
  useEffect(() => {
    fetchOrganizationDetails();
  }, []);
  useEffect(() => {
    let totalSum = 0;
    pieChartData.forEach((d) => (totalSum += d.value));
    setScopeSum(totalSum);
  }, [pieChartData]);
  useEffect(() => {
    reshapeData();
  }, [data]);

  return (
    <div>
      <Spinner size={80} data-testid="spinner" open={loading} />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mr: 5 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Net Zero Carbonization Model
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Baseline and Target Emission Analysis
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Metrics
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={"row"}>
            <Typography variant="body1" sx={{ m: 1 }}>
              Scope{" "}
            </Typography>
            <MultiSelect
              dataTestId={"scopeSelect"}
              options={scopesCoveredOptions}
              onSelectionChange={handleScopeChange}
              selectedOptions={selectedScope}
            />
          </Stack>
        </Grid>
        <Grid item xs={4} hidden={true}>
          <Stack direction={"row"}>
            <Typography variant="body1" sx={{ m: 1 }}>
              Business Unit{" "}
            </Typography>
            <MultiSelect
              options={businessUnitOptions}
              onSelectionChange={handleBusinessUnitChange}
              selectedOptions={selectedBU}
            />
          </Stack>
        </Grid>

        <Grid item xs={4.8}>
          <Paper variant="outlined">
            <Grid item sx={{ m: 1 }}>
              <div
                style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#05004E",
                  width: "100%",
                }}
              >
                Baseline Year & Total Emissions (MT CO₂e)
              </div>
            </Grid>
            <Stack direction={"row"}>
              <Grid
                item
                xs={6}
                sx={{
                  boxShadow: 1,
                  p: 1,
                  m: 1,
                  borderRadius: 5,
                  backgroundImage: `url(${group1})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid item>
                  <Stack direction={"column"}>
                    <Typography sx={{ fontSize: "24px", flex: "1" }}>
                      <b>2021</b>
                    </Typography>
                    <div>Baseline Year</div>
                  </Stack>
                </Grid>
                <Grid item alignSelf={"end"}>
                  <img
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    src={calendar}
                    alt="calender"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  boxShadow: 1,
                  p: 1,
                  m: 1,
                  borderRadius: 5,
                  backgroundImage: `url(${group1})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid item>
                  <Stack direction={"column"}>
                    <Typography
                      sx={{ fontSize: "24px", flex: "1" }}
                      data-testid={"totalemission"}
                    >
                      <b>
                        {scopeSum.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}
                      </b>
                    </Typography>
                    <div>Total Emissions</div>
                  </Stack>
                </Grid>
                <Grid item alignSelf={"end"}>
                  <img
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    src={co2}
                    alt="co2"
                  />
                </Grid>
              </Grid>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={7.2}>
          <Paper variant="outlined">
            <Grid item sx={{ m: 1 }}>
              <div
                style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#05004E",
                  width: "100%",
                }}
              >
                Total Emissions By Scope (MT CO₂e)
              </div>
            </Grid>

            <Stack direction={"row"} justifyItems={"center"}>
              {emissionByScope()}
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: 4, mb: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Baseline Assessment
          </Typography>
        </Grid>
        
        <Grid item xs={5.8}>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <EmissionByScopePie
              width={defaultWidth}
              height={defaultPieChatHeight}
              data={pieChartData}
            />
          </Paper>
        </Grid>
        <Grid item xs={6.2}>
          <Paper variant="outlined" sx={{ p: 1 }}>
            {" "}
            <EmissionByScopeCategoryBarChart
              width={defaultBarChatWidth}
              categoryHeight={CATEGORY_HEIGHT}
              height={defaultHeight}
              data={barChartData}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

// External dependencies
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// Material-UI components
import {
  Box,
  Grid,
  TextField,
  FormControl,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

// Internal dependencies
import RequiredLabel from "pages/TargetForm/RequiredLabel";
import Spinner from "components/Spinner";
import { _addUser, _updateUser } from "middlewares/ManageUsers/manageusers";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import { userRoleModel } from "./data/userRoleModel";
import { userRoles } from "./data/userRoles";
import { useAppState } from "context/appState.context";
import { UserRoles } from "constants/constant";

// Interface for the props of the AddUser component
interface AddUserProps {
  handleCloseSnackBar: (state: AlertsProps) => void;
  hideDialogHandler: () => void;
  getUserRoles: () => void;
  rowData?: userRoleModel | null;
}

/**
 * AddUser component
 *
 * This component renders a form for adding or updating a user. It handles form submission, input changes, and API calls for saving user data.
 *
 * @param {AddUserProps} props - The props for the component.
 * @param {Function} props.handleCloseSnackBar - Function to handle closing the snackbar.
 * @param {Function} props.hideDialogHandler - Function to hide the dialog.
 * @param {Function} props.getUserRoles - Function to fetch user roles.
 * @param {userRoleModel | null} [props.rowData] - Optional data for pre-filling the form when editing a user.
 * @returns {JSX.Element} A form for adding or updating a user.
 */
const AddUser = (props: AddUserProps) => {
  // Extracting OrganizationId from URL parameters
  const { OrganizationId } = useParams();
  const parsedOrganizationId = Number(OrganizationId);

  // State for user data and loading status
  const [user, setUser] = useState<userRoleModel>({
    userRoleId: 0,
    userName: "",
    userEmail: "",
    company: "",
    roleId: "",
    orgId: parsedOrganizationId,
    organizationMapping: "",
  });
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setValue } = useForm();
  const { userRole } = useAppState();

  useEffect(() => {
    if (props.rowData) {
      setUser(props.rowData);
      setValue("userName", props.rowData.userName);
      setValue("userEmail", props.rowData.userEmail);
      setValue("company", props.rowData.company);
      setValue("roleId", props.rowData.roleId?.toString() ?? "");
    }
  }, [props.rowData, setValue]);

  // Handle input change for text fields
  /**
   * Handles input changes for text fields.
   *
   * @param {ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event.
   */
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  // Handle select change for Role dropdown
  /**
   * Handles select changes for the Role dropdown.
   *
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent | SelectChangeEvent<number>} event - The change event.
   */
  const handleRoleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
      | SelectChangeEvent<number>
  ) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  /**
   * Handles saving or updating a user by calling the appropriate API function.
   *
   * @param {Function} apiFunction - The API function to call (_addUser or _updateUser).
   */
  const handleSaveOrUpdate = (apiFunction: Function) => {
    if (typeof apiFunction === "function") {
      setLoading(true);
      apiFunction(user)
        .then((res: userRoleModel) => {
          if (res) {
            setLoading(false);
            const message =
              user.userRoleId === 0
                ? `${user.userName} Created Successfully !`
                : "Data Saved Successfully !";
            props.handleCloseSnackBar({
              show: true,
              severity: "success",
              message: message,
            });
            props.hideDialogHandler();
            props.getUserRoles();
          }
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  };

  /**
   * Determines whether to save or update the user based on the userRoleId.
   */
  const onSave = () => {
    if (user.userRoleId > 0) {
      handleSaveOrUpdate(_updateUser);
    } else {
      handleSaveOrUpdate(_addUser);
    }
  };

  // JSX structure for the AddUser component
  return (
    <Box sx={{ m: 2 }}>
      <form onSubmit={handleSubmit(onSave)} data-testid="form">
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <RequiredLabel
              labelText="Display Name"
              isHelperTextRequired={false}
              content=""
            />
            <TextField
              inputProps={{ "data-testid": "userName" }}
              {...register("userName", {
                required: true,
              })}
              required
              name="userName"
              value={user.userName ?? ""}
              size="small"
              label="Display Name"
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <RequiredLabel
              labelText="Email"
              isHelperTextRequired={false}
              content=""
            />
            <TextField
              inputProps={{ "data-testid": "useremail" }}
              {...register("userEmail", {
                required: true,
              })}
              name="userEmail"
              value={user.userEmail ?? ""}
              size="small"
              label="Email"
              fullWidth
              required
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <RequiredLabel
              labelText="Company"
              isHelperTextRequired={false}
              content=""
            />
            <TextField
              inputProps={{ "data-testid": "company" }}
              {...register("company", {
                required: true,
              })}
              name="company"
              value={user.company ?? ""}
              size="small"
              label="Company"
              fullWidth
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <RequiredLabel
              labelText="Role"
              isHelperTextRequired={false}
              content=""
            />
            <FormControl fullWidth>
              <Select
                data-testid="roleId"
                {...register("roleId", {
                  required: true,
                })}
                required
                value={user.roleId?.toString() ?? ""}
                onChange={handleRoleChange}
                displayEmpty
                size="small"
                name="roleId"
                fullWidth
              >
                <MenuItem value="">Select Role</MenuItem>
                {userRoles
                  .filter((role) => {
                    if (userRole === UserRoles.Admin && role.label === UserRoles.SuperAdmin) {
                      return false;
                    }
                    return true;
                  })
                  .map((role) => (
                    <MenuItem key={role.id} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button type="submit" variant="contained" data-testid="btnSave">
            Save User
          </Button>
        </Grid>
      </form>
      {loading && <Spinner size={80} data-testid="spinner" />}
    </Box>
  );
};

export default AddUser;

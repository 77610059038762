import React from "react";
import { TableRow, TableCell, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { PriceModel } from "./data/PriceModel";

interface BaseValueRowProps {
  priceData: PriceModel[];
  onChange: (id: string, value: string) => void;
}

const CustomTableCell = styled(TableCell)({
  padding: "9px", // Adjust the padding as needed
});

/**
 * Component that renders a table row with `TextField` inputs for base values for each `PriceModel` item.
 *
 * @param {BaseValueRowProps} props - The props for the component.
 * @param {PriceModel[]} props.priceData - The array of `PriceModel` objects containing the data to display.
 * @param {(id: number, value: string) => void} props.onChange - The callback function to handle changes in `TextField` values.
 * @returns {React.ReactElement} - A `TableRow` element containing `TextField` inputs for base values.
 */
const BaseValueRow: React.FC<BaseValueRowProps> = ({
  priceData,
  onChange,
}: BaseValueRowProps): React.ReactElement => (
  <TableRow>
    <CustomTableCell>Base value</CustomTableCell>
    {priceData.map((item) => (
      <CustomTableCell key={`basevalue-${item.uniqueId}`}>
        <TextField
          value={item.baseValue || ""}
          onChange={(e) => onChange(item.uniqueId, e.target.value)}
          type="number"
          size="small"
          id={`basevalue-${item.uniqueId}`}
          name={`basevalue-${item.uniqueId}`}
          fullWidth
          inputProps={{
            "data-testid": `baseValueId-${item.uniqueId}`,
            inputMode: "numeric",
            maxLength: 3,
          }}
        />
      </CustomTableCell>
    ))}
  </TableRow>
);

export default BaseValueRow;

import apiHandler from "middlewares/apiHandler";
import { userRoleModel } from "pages/ManageUsers/data/userRoleModel";

export const _addUser = async (userRoleModel: userRoleModel) => {
  return apiHandler.post<userRoleModel>("UserManager", userRoleModel);
};

export const _getUserRoles = async (orgId: number | undefined) => {
  return apiHandler.get<Array<userRoleModel>>(
    `UserManager/${orgId}`
  );
};

export const _getUserRole = async (userEmail: string) => {
  return apiHandler.post<string>("UserManagerRole", userEmail);
};

export const _deleteUserRole = async (userRoleId: number) => {
  return apiHandler.delete("UserManager/" + userRoleId);
};

export const _updateUser = async (userRoleModel: userRoleModel) => {
  return apiHandler.put<userRoleModel>("UserManager", userRoleModel);
};
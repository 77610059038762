import React, { useEffect, useState } from "react"; // Import React and necessary hooks

// Import Material-UI components and icons
import {
  Divider,
  Grid,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Paper,
  IconButton,
  FormLabel,
  Slider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Import necessary data and components
import {
  ProjectRegisterOptions,
  baseYears,
  metrics,
  scenarioOptions,
  scopesCoveredOptions,
  targetYears,
} from "./data/ProjectRegisterData";
import ProjectRegisterTable from "./ProjectRegisterTable";
import ProjectMetrics from "./ProjectMetrics";
import { FilterMetric } from "./data/FilterMetric";
import { _getMarginalAbatementCost } from "middlewares/DashboardApi/projectMetrics";
import { MarginalAbatementCostModel } from "./data/MaginalAbatementResult";
import { useParams } from "react-router-dom";
import Spinner from "components/Spinner";
import { ProjectRegisterMetrics } from "constants/constant";
import { _getOrganization } from "middlewares/OrganizationApi/organization";
import MarginalAbatementCostCurve from "./MarginalAbatementCostCurve";
import MeasureImpactsbyScope from "./MeasureImpactsbyScope";
import { MIBScopeData } from "./data/MIBScopeData";
import TimelineAndFinancialMetrics from "./TimelineAndFinancialMetrics";
import { TLFMetricsData } from "./data/TLFMetricsData";
import { MACgraphModel } from "./data/MACgraphModel";
import { generateData } from "./data/metricsUtils";
import InteractiveGlidepath from "./InteractiveGlidepath";

/**
 * ProjectRegister component is used to display and manage project registration data.
 * It includes filters, metrics, and different views such as Marginal Abatement Cost Curve,
 * Measure Impacts by Scope, and Timeline and Financial Metrics.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const ProjectRegister = () => {
  // Params
  const { OrganizationId } = useParams();

  // State variables
  const [selectedOption, setSelectedOption] = useState(
    ProjectRegisterMetrics.MarginalAbatementCostCurve
  );
  const [filterState, setFilterState] = useState<FilterMetric>({
    orgId: Number(OrganizationId),
    dashboardSection: selectedOption,
    ghgScope: null,
    startYear: null,
    endYear: null,
    scenario: null,
    businessUnit: null,
    minEmissionReduction: null,
    maxEmissionReduction: null,
    minMAC: null,
    maxMAC: null,
  });
  const [loading, setLoading] = useState(true);
  const [marginalAbatementCost, setMarginalAbatementCost] = useState<
    Array<MarginalAbatementCostModel>
  >([]);

  const [metricsData, setMetricsData] = useState(metrics);
  const [showFilters, setShowFilters] = useState(false);

  const [emissionReduction, setEmissionReduction] = React.useState<number[]>(
    []
  );
  const [marginalAbatement, setMarginalAbatement] = React.useState<number[]>(
    []
  );

  const [minEmission, setMinEmission] = React.useState<number>(0);
  const [maxEmission, setMaxEmission] = React.useState<number>(0);

  const [minMarginal, setMinMarginal] = React.useState<number>(0);
  const [maxMarginal, setMaxMarginal] = React.useState<number>(0);

  const [businessUnitList, setBusinessUnitList] = useState(
    [] as Array<string | undefined>
  );

  const [macData, setMacData] = useState<MACgraphModel[]>([]);
  const [mibScopeData, setMibScopeData] = useState<MIBScopeData[]>([]);
  const [tlfMetricsData, setTlfMetricsData] = useState<TLFMetricsData[]>([]);
  const [interactiveGlidepathData, setInteractiveGlidepathData] = useState<TLFMetricsData[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isGlidepath, setIsGlidepath] = useState(false);
  const [isMacDataLoaded, setIsMacDataLoaded] = useState(false);

  interface InteractiveGlidepathMetric {
    measureName: string;
    year: number;
    emissionReduction: number;
  }

  /**
   * Handles the change in Marginal Abatement Cost slider.
   *
   * @param {Event} event - The event object.
   * @param {number | number[]} newValue - The new value of the slider.
   */
  const handleMarginalChange = (event: Event, newValue: number | number[]) => {
    const [minValue, maxValue] = newValue as number[];
    setMarginalAbatement(newValue as number[]);
    // Update filterState
    setFilterState((prevState) => ({
      ...prevState,
      minMAC: String(minValue),
      maxMAC: String(maxValue),
    }));
  };

  /**
   * Handles the change in Emission Reduction slider.
   *
   * @param {Event} event - The event object.
   * @param {number | number[]} newValue - The new value of the slider.
   */
  const handleEmissionChange = (event: Event, newValue: number | number[]) => {
    const [minValue, maxValue] = newValue as number[];
    setEmissionReduction(newValue as number[]);
    // Update filterState
    setFilterState((prevState) => ({
      ...prevState,
      minEmissionReduction: String(minValue),
      maxEmissionReduction: String(maxValue),
    }));
  };
  // Handler for changing the selected option
  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);

    setFilterState((prevState) => ({
      ...prevState,
      dashboardSection: event.target.value,
    }));
  };

  /**
   * Handles the change in filter values.
   *
   * @param {keyof FilterMetric} key - The filter key.
   * @param {string | null | number} value - The new value of the filter.
   */
  const handleFilterChange = (
    key: keyof FilterMetric,
    value: string | null | number
  ) => {
    const newValue = value === "0" ? null : value;
    setFilterState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  /**
   * Toggles the visibility of filters.
   */
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  /**
   * Fetches the marginal abatement cost data.
   */
  const getMarginalAbatementCost = async () => {
    try {
      setLoading(true);
      const res = await _getMarginalAbatementCost(filterState);
      if (res) {
        if (
          selectedOption === ProjectRegisterMetrics.MarginalAbatementCostCurve
        ) {
          setMacData(res.maCgraphModelList);
          setIsMacDataLoaded(true);
          initializeEmissionAndAbatement(res);
        }
        if (
          selectedOption == ProjectRegisterMetrics.TimelineandFinancialMetrics
        ) {
          const data = generateData(res,false);
          setTlfMetricsData(data);
          setIsDataLoaded(true);
        }
        if (selectedOption === ProjectRegisterMetrics.MeasureImpactsbyScope) {
          const data: MIBScopeData[] = res.impactByScopeModelList.map(
            (item: any, index: any) => {
              const mibScopeData: MIBScopeData = {
                label: item.reductionMeasureName,
                value: item.emissionReduction,
              };
              return mibScopeData;
            }
          );
          setMibScopeData(data);
        }
        setMarginalAbatementCost(res.marginalAbatementCost);
        setMetricsData((prevMetricsData) =>
          prevMetricsData.map((metric) => ({
            ...metric,
            value: res.metrics[metric.key],
          }))
        );
        if(selectedOption === ProjectRegisterMetrics.InteractiveGlidepath){
          const reorderedMetrics = reorderMetrics(res.interactiveGlidepathMetric);
          const data= generateData(reorderedMetrics,true);
          setInteractiveGlidepathData(data);
          setIsGlidepath(true);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const reorderMetrics = (metrics: InteractiveGlidepathMetric[]) => {
    // Group by measureName
    const groupedMetrics = metrics.reduce((acc, item) => {
      const { measureName } = item;
      if (!acc[measureName]) {
        acc[measureName] = [];
      }
      acc[measureName].push(item);
      return acc;
    }, {} as Record<string, InteractiveGlidepathMetric[]>);
  
    // Sort each group by year
    Object.keys(groupedMetrics).forEach(measure => {
      groupedMetrics[measure].sort((a, b) => a.year - b.year);
    });
  
    // Flatten the grouped object back into an array
    return Object.values(groupedMetrics).flat();
  };

  const initializeEmissionAndAbatement = (res: any) => {
    const emissionReductions = res.marginalAbatementCost.map(
      (item: any) => item.emissionReduction
    );
    const marginalAbatementCosts = res.marginalAbatementCost.map(
      (item: any) => item.mac
    );

    let minEmission = Math.min(...emissionReductions);
    let maxEmission = Math.max(...emissionReductions);
    let minMarginalAbatementCost = Math.min(...marginalAbatementCosts);
    let maxmarginalAbatementCost = Math.max(...marginalAbatementCosts);
    // Handle case where both min and max values are zero
    if (minEmission === 0 && maxEmission === 0) {
      maxEmission = 100; // Default max value
    }

    if (minMarginalAbatementCost === 0 && maxmarginalAbatementCost === 0) {
      maxmarginalAbatementCost = 100; // Default max value
    }
    setMinEmission(minEmission);
    setMaxEmission(maxEmission);
    setEmissionReduction([minEmission, maxEmission]);

    setMinMarginal(minMarginalAbatementCost);
    setMaxMarginal(maxmarginalAbatementCost);
    setMarginalAbatement([minMarginalAbatementCost, maxmarginalAbatementCost]);
  };

  useEffect(() => {
    getMarginalAbatementCost();
    setIsDataLoaded(false);
    setIsMacDataLoaded(false);
    setIsGlidepath(false);
  }, [filterState]);

  const fetchOrganizationDetails = async () => {
    try {
      const res = await _getOrganization(Number(OrganizationId));
      if (res) {
        const filteredBusinessUnits = res?.businessUnits
          ?.filter((prop) => prop.name) // Remove undefined names
          ?.map((prop) => prop.name);
        setBusinessUnitList(filteredBusinessUnits ?? []);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMarginalAbatementCost();
  }, []);

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mr: 5 }}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                fontSize: "24px",
                color: "#0C0808",
                padding: "8px",
              }}
              data-testid="netZeroCarbonizationLabel"
            >
              Net Zero Carbonization Model
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#0C0808",
                marginLeft: "6px",
              }}
              data-testid="marginalAbatementCostCurveLabel"
            >
              Marginal Abatement Cost
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <FormControl fullWidth>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                sx={{ width: "320px" }}
                id="projectRegisterOptionsDrodpdown"
                data-testid="projectRegisterOptionsDrodpdown"
              >
                {ProjectRegisterOptions.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.value}
                    sx={{ textAlign: "left" }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          style={{
            padding: "16px",
            marginLeft: "5px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <button
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: "none",
              border: "none",
              padding: 0,
              width: "100%", // Add this line to make the button take full width
            }}
            data-testid="toggleFiltersButon"
            onClick={toggleFilters}
            aria-pressed={showFilters}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#27272E",
                marginLeft: "4px",
              }}
              data-testid="filtersLabel"
            >
              Filters
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              <IconButton>
                {showFilters ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </div>
          </button>
          {showFilters && (
            <>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel id="scopes-dropdown-label">Scopes</FormLabel>
                    <Select
                      value={filterState.ghgScope ?? "0"}
                      onChange={(e) =>
                        handleFilterChange("ghgScope", e.target.value)
                      }
                      sx={{ width: "310px" }}
                      data-testid="ghgScope"
                    >
                      <MenuItem value="0">Select Scopes</MenuItem>
                      {scopesCoveredOptions.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel id="targetyear-dropdown-label">
                      Start Year
                    </FormLabel>
                    <Select
                      value={filterState.startYear ?? "0"}
                      onChange={(e) =>
                        handleFilterChange("startYear", e.target.value)
                      }
                      sx={{ width: "310px" }}
                    >
                      <MenuItem value="0">Select Start Year</MenuItem>
                      {baseYears.map((option, index) => (
                        <MenuItem key={index + option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel id="targetyear-dropdown-label">
                      End Year
                    </FormLabel>
                    <Select
                      value={filterState.endYear ?? "0"}
                      onChange={(e) =>
                        handleFilterChange("endYear", e.target.value)
                      }
                      sx={{ width: "310px" }}
                    >
                      <MenuItem value="0">Select End Year</MenuItem>
                      {targetYears.map((option, index) => (
                        <MenuItem key={index + option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel id="scenario-dropdown-label">Scenario</FormLabel>
                    <Select
                      value={filterState.scenario ?? "0"}
                      onChange={(e) =>
                        handleFilterChange("scenario", e.target.value)
                      }
                      sx={{ width: "310px" }}
                    >
                      <MenuItem value="0">Select Scenario</MenuItem>
                      {scenarioOptions.map((option, index) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <FormLabel id="businessunits-dropdown-label">
                      Business Units
                    </FormLabel>
                    <Select
                      value={filterState.businessUnit ?? "0"}
                      onChange={(e) =>
                        handleFilterChange("businessUnit", e.target.value)
                      }
                      sx={{ width: "310px" }}
                    >
                      <MenuItem value="0">All</MenuItem>
                      {businessUnitList.map((businessunit, index) => (
                        <MenuItem
                          key={index + (businessunit ?? "")}
                          value={businessunit}
                        >
                          {businessunit}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {selectedOption !==
              ProjectRegisterMetrics.MarginalAbatementCostCurve ? (
                <Grid container>
                  <Grid item xs={3} sx={{ mr: 12 }}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel id="businessunits-dropdown-label">
                        Emissions Reduction (MTCO₂e)
                      </FormLabel>
                      <Slider
                        value={emissionReduction}
                        onChange={handleEmissionChange}
                        valueLabelDisplay="auto"
                        min={minEmission}
                        max={maxEmission}
                        data-testid="emissionReductionSlider"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3.5}>
                    <FormControl fullWidth margin="normal">
                      <FormLabel id="businessunits-dropdown-label">
                        Marginal Abatement Cost ($/ MT CO₂e)
                      </FormLabel>
                      <Slider
                        value={marginalAbatement}
                        onChange={handleMarginalChange}
                        valueLabelDisplay="auto"
                        min={minMarginal}
                        max={maxMarginal}
                        data-testid="marginalAbatement"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </>
          )}
        </Paper>
      </Grid>
      <br />
      <Divider />
      {loading ? (
        <Spinner size={80} data-testid="spinner" />
      ) : (
        <>
          <Grid container spacing={2}>
            <ProjectMetrics metrics={metricsData}></ProjectMetrics>
          </Grid>
          <Grid style={{ width: "100%" }}>
            {selectedOption ===
              ProjectRegisterMetrics.MarginalAbatementCostCurve &&
              isMacDataLoaded && <MarginalAbatementCostCurve data={macData} />}
            {selectedOption ===
              ProjectRegisterMetrics.MeasureImpactsbyScope && (
              <MeasureImpactsbyScope data={mibScopeData} />
            )}
            {selectedOption ===
              ProjectRegisterMetrics.TimelineandFinancialMetrics &&
              isDataLoaded && (
                <TimelineAndFinancialMetrics
                  data={tlfMetricsData}
                  width={1000}
                  height={800}
                />
              )}
            {selectedOption === ProjectRegisterMetrics.InteractiveGlidepath && isGlidepath && (
              <InteractiveGlidepath data={interactiveGlidepathData} width={1100} height={600} />
            )}
          </Grid>
          <Grid style={{ width: "100%" }}>
            <ProjectRegisterTable
              marginalAbatementCost={marginalAbatementCost}
            ></ProjectRegisterTable>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProjectRegister;

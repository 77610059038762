import { TLFMetricsData } from "./TLFMetricsData";

export const generateYearsArray = (
  startYear: number,
  endYear: number,
  step: number
): number[] => {
  const years: number[] = [];
  for (let year = startYear; year <= endYear; year += step) {
    years.push(year);
  }
  return years;
};

const extractMeasures = (apiResponse: any, isGlidepath: boolean): string[] => {
  if (isGlidepath) {
    return apiResponse.map((item: any) => item.measureName);
  }
  return apiResponse.timelineFinancialMetric.map(
    (item: any) => item.measureName
  );
};

const findEmissionReduction = (
  year: number,
  measure: string,
  apiResponse: any,
  isGlidepath: boolean
): number => {
  const measureData = isGlidepath
    ? apiResponse.find(
        (item: any) => item.measureName === measure && item.year === year
      )
    : apiResponse.timelineFinancialMetric.find(
        (item: any) => item.measureName === measure && item.year === year
      );
  return measureData ? measureData.emissionReduction : 0;
};

const createDataPoint = (
  year: number,
  measures: string[],
  apiResponse: any,
  isGlidepath: boolean
): TLFMetricsData => {
  const dataPoint: TLFMetricsData = { year: year.toString() };
  measures.forEach((measure) => {
    dataPoint[measure] = findEmissionReduction(
      year,
      measure,
      apiResponse,
      isGlidepath
    );
  });
  return dataPoint;
};

const generateMetricsData = (
  years: number[],
  measures: string[],
  apiResponse: any,
  isGlidepath: boolean
): TLFMetricsData[] => {
  return years.map((year) =>
    createDataPoint(year, measures, apiResponse, isGlidepath)
  );
};

export const generateData = (
  apiResponse: any,
  isGlidepath: boolean
): TLFMetricsData[] => {
  const years = generateYearsArray(2020, 2050, 1);
  const measures = extractMeasures(apiResponse, isGlidepath);
  return generateMetricsData(years, measures, apiResponse, isGlidepath);
};



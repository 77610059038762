import { AlertNotificationConfig } from "object-models/shared/alertNotification-config.model";
import { createContext, useContext } from "react";

/**
 * Defines the structure of the AppState context.
 * @typedef {Object} AppStateContextType
 * @property {AlertNotificationConfig} notificationConfig - The configuration for alert notifications.
 * @property {function(AlertNotificationConfig):void} setNotificationConfig - Function to set the alert notification configuration.
 * @property {number} clientId - The client ID.
 * @property {string} userRole - The role of the user.
 * @property {function(string):void} setUserRole - Function to set the user role.
 * @property {function(number):void} setClientId - Function to set the client ID.
 */
export type AppStateContextType = {
  notificationConfig: AlertNotificationConfig;
  setNotificationConfig: (
    alertNotificationConfig: AlertNotificationConfig
  ) => void;
  clientId: number;
  userRole:string;
  setUserRole: (userRole: string) => void;
  setClientId: (clientId: number) => void;
};

/**
 * Creates a context for the application state with default values.
 * @type {React.Context<AppStateContextType>}
 */
export const AppStateContext = createContext<AppStateContextType>({
  clientId: 0,
  setClientId: (clientId) => {
    console.warn("no theme provider");
  },
  userRole:"",
  setUserRole: (userRole) => {
    console.warn("no theme provider");
  },
  notificationConfig: {
    message: "",
    open: false,
    severity: undefined,
  },
  setNotificationConfig: (alertNotificationConfig) =>
    console.warn("no theme provider"),
});

/**
 * Custom hook to use the AppState context.
 * @returns {AppStateContextType} The application state context.
 */
export const useAppState = () => useContext(AppStateContext);

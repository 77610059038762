import axios, { AxiosRequestHeaders } from "axios";
import authOperations from "configs/authService";
import { ExceptionModel } from "object-models/shared/exception.model";
import appStorage from "utilities/app-storage";
import { Utilities } from "utilities/utilities";

class CommonApiHandler {
  baseURL = "/api/";

  get<T>(url: string): Promise<T> {
    authOperations.getRefreshToken();
    url = this.getResourceURL(url);
    const header = this.getHeaders();
    return axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((ex) => {
        return this.exceptionHandler(ex);
      });
  }

  delete<T>(url: string): Promise<T> {
    authOperations.getRefreshToken();
    url = this.getResourceURL(url);
    const header = this.getHeaders();
    return axios
      .delete(url, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((ex) => {
        this.exceptionHandler(ex);
      });
  }

  post<T>(url: string, data: T): Promise<T> {
    authOperations.getRefreshToken();
    url = this.getResourceURL(url);
    const header = this.getHeaders();
    return axios
      .post(url, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((ex) => {
        this.exceptionHandler(ex);
      });
  }

  put<T>(url: string, data: T): Promise<T> {
    authOperations.getRefreshToken();
    url = this.getResourceURL(url);
    const header = this.getHeaders();
    return axios
      .put(url, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((ex) => {
        this.exceptionHandler(ex);
      });
  }

  private getResourceURL(url: string): string {
    this.baseURL =
      process.env.NODE_ENV === "development"
        ? "http://localhost:7157/api/"
        : this.baseURL;
    return this.baseURL + url;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private exceptionHandler(ex: any) {
    if (ex.response) {
      return this.handleException(ex.response as ExceptionModel);
    } else {
      throw ex;
    }
  }

  private getHeaders(): AxiosRequestHeaders {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${appStorage.getAccessToken()}`,
    } as AxiosRequestHeaders;
  }

  private handleException(ex: ExceptionModel) {
    if (ex) {
      const response = ex;

      if (response.status === 401) {
        if (Utilities.isTokenExpired()) {
          Utilities.redirectToLogout();
        } else {
          alert(
            "UnAuthorized! Sorry you don't have permissions to access this functionality."
          );
        }
      } else if (response.status === 400) {
        throw response.data.Message ?? response.data;
      } else if (response.data) {
        alert(response.data);
      } else if (response.status === 500 || !response.data) {
        alert(
          "something went wrong please retry and if error persist please contact to administrator."
        );
      } else {
        throw ex;
      }
    }
  }
}

const apiHandler = new CommonApiHandler();
export default apiHandler;
